import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextArea = makeShortcode("TextArea");
const TextColumn = makeShortcode("TextColumn");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextArea mdxType="TextArea">
      <TextColumn mdxType="TextColumn">
        <h1>{`Privacy Policy`}</h1>
        <p>{`This website collects personal information posted via the contact form on the 'Contact' page (`}<a parentName="p" {...{
            "href": "/contact"
          }}>{`here`}</a>{`).`}</p>
        <h2>{`What personal identification information is collected`}</h2>
        <p>{`If you submit your information via the contact form, the following personal information is collected.`}</p>
        <ul>
          <li parentName="ul">{`Your name, as entered in the 'Name' field.`}</li>
          <li parentName="ul">{`Your email address, as entered in the 'Email' field.`}</li>
          <li parentName="ul">{`Any personal information you enter into the 'Message' field.`}</li>
        </ul>
        <h2>{`How that personal identification information is used`}</h2>
        <p>{`Personal information entered in the contact form is used by the site administrator to respond to your message.`}</p>
        <h2>{`Who that personal identification information is shared with`}</h2>
        <p>{`Your personal information is shared with the site administrator (Emily-Rose Cripps) and `}<a parentName="p" {...{
            "href": "https://www.netlify.com/"
          }}>{`Netlify`}</a>{`, who host the site and store all contact form submissions in their database. Netlify have a separate privacy policy, `}<a parentName="p" {...{
            "href": "https://www.netlify.com/privacy"
          }}>{`here`}</a>{`.`}<br parentName="p"></br>{`
`}{`This data may also be shared with anybody that the site administrator allows to login to their Netlify account, or whom they Cc or Bcc into email correspondence.`}</p>
        <h2>{`Links`}</h2>
        <p>{`This site links off to external sites that host their own privacy policies. We are not responsible for the privacy practises of any linked site.`}</p>
        <h2>{`Security`}</h2>
        <p>{`We do our best to ensure that this site is free from any malicious software and viruses, however we would reccommend that you ensure you have security software installed on your device and treat any downloaded content with caution.`}</p>
      </TextColumn>
    </TextArea>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      